import Layout from "@/components/Layout";
import Header from "components/Header";
import NoContent from "@/components/NoContent";
import { Container, Text, Center, Stack, Link } from "@chakra-ui/react";
import BlackButton from "@/components/BlackButton";

export default function Custom404() {
  return (
    <Layout>
      <Header browserTitle="WellFitly: Empowering Your Wellness Journey with the Right Professionals" />
      <Container maxW={"7xl"} minH={"100vh"} p="6">
        <NoContent />
        <Center>
          <Stack>
            <Text
              color={"gray.400"}
              fontWeight={"extrabold"}
              fontFamily={"Yeseva"}
              fontSize={"3xl"}
              align={"center"}
            >
              The page you are looking for might have been removed or is
              temporarily unavailable.
            </Text>
            <Center>
              <Link href={"/"}>
                <BlackButton
                  id="btn-404-home"
                  buttonText="Go to WellFitly Home Page"
                />
              </Link>
            </Center>
          </Stack>
        </Center>
      </Container>
    </Layout>
  );
}
