import { memo } from "react";
const { Button } = require("@chakra-ui/react");

const BlackButton = ({ id, type, isLoading, buttonText }) => {
  return (
    <Button
      id={id}
      type={type}
      disabled={isLoading}
      fontFamily={"heading"}
      borderRadius={50}
      w={"full"}
      bgGradient="linear(to-r, black,gray.800)"
      color={"white"}
      _hover={{
        bgGradient: "linear(to-r, black.400,black.400)",
        boxShadow: "xl",
      }}
    >
      {isLoading ? "Please wait..." : buttonText}
    </Button>
  );
};

export default memo(BlackButton);
